.gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    overflow: hidden;
  }
  
  .image-container img {
    height: 100%;
    width: auto;
    object-fit: cover;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image-container img.active {
    opacity: 1;
  }
  
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #ffffff;
    z-index: 1;
  }
  
  .prev-btn {
    left: 20px;
  }
  
  .next-btn {
    right: 20px;
  }
  
  .gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
  }
  
  img {
    max-width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  img.active {
    opacity: 1;
  }
  
  .prev-btn,
  .next-btn {
    margin-top: 30px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    background-color: #333;
    color: #fff;
  }
  