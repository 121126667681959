/* App.css */

/* Style de l'en-tête */
.header {
  background-image: linear-gradient(to bottom right, #0074D9, #b7e7cb);
  color: #fff;
  padding: 40px;
  text-align: center;
}

/* Style de la photo de profil */
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -100px;
}



/* Style du menu de navigation */
.navbar {
  background-color: #f8f9fa;
  padding: 20px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.navbar li {
  display: inline-block;
  margin: 0 10px;
}

.navbar a {
  color: #007bff;
  text-decoration: none;
}

.navbar a:hover {
  color: #0056b3;
}

/* Style des sections de contenu */
.section {
  padding: 60px 0;
}

.section h2 {
  margin-bottom: 40px;
}

.section h3 {
  margin-top: 0;
}

/* Style du pied de page */
.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.profile-img {
  margin-top: 2px;
}



.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-card {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.project-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-school {
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}

.project-competition {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.project-link {
  font-size: 16px;
  font-weight: 500;
  color: #007bff;
}

@media (min-width: 768px) {
  .project-card {
    width: calc(33.33% - 10px);
    margin-right: 10px;
  }

  .project-card:last-child {
    margin-right: 0;
  }
}

.thesis-list {
  margin-top: 20px;
}

.thesis-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.thesis-item h3 {
  margin-top: 0;
}

.thesis-item p {
  margin-bottom: 5px;
}

.thesis-item strong {
  font-weight: bold;
}
